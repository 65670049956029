const FIREBASE_API_KEY = "AIzaSyAKMozG84lfhZYY98m2GuIQYkOmCdr7nEo"
const FIREBASE_AUTH_DOMAIN = "sharedecommerceproject.firebaseapp.com"
const FIREBASE_PROJECT_ID = "sharedecommerceproject"
const FIREBASE_STORAGE_BUCKET = "sharedecommerceproject.appspot.com"
const FIREBASE_MESSAGE_SENDER_ID = "598994614607"
const FIREBASE_APP_ID = "1:598994614607:web:aca313800afc9d97eaad3a"
const FIREBASE_MEASURMENT_ID = "G-LEB54WCN00"
const FIREBASE_VAP_ID_KEY = "BGOpMnyTClgXLPk5XgEj950m-9TR0Sh1oVPawBevMroSGX5QqT2N7YBN-FZfwrbzQwZ-si3AW6ANEXko9mrablo"

export default {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGE_SENDER_ID,
    FIREBASE_APP_ID,
    FIREBASE_MEASURMENT_ID,
    FIREBASE_VAP_ID_KEY,
}